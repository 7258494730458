.create-meeting-form-group {
  margin-top: 4rem;
}

.edit-meeting .create-meeting-form-group {
  margin-top: 3rem;
}

.create-meeting-question {
  font-size: 1.3em;
}

.create-meeting-button {
  min-width: 8em;
}

.meeting-times-picker {
  border: 1px solid lightgray;
  box-shadow: 0 0 5px gray;
  background-color: white;  /* TODO: dark theme */
  border-radius: 0.375rem;
  min-width: 10em;
}

.meeting-times-picker-top {
  padding: 0.375rem 0.75rem;
  border-bottom: 1px solid lightgray;
}

.meeting-times-picker-left, .meeting-times-picker-right {
  list-style: none;
  padding: 0;
  margin: 0;
}

.meeting-times-picker-left {
  border-right: 1px solid lightgray;
  max-height: 12em;
  overflow-y: scroll;
}

:is(.meeting-times-picker-left, .meeting-times-picker-right) > li {
  padding: 0.2rem 1.2rem;
  text-align: center;
}

:is(.meeting-times-picker-left, .meeting-times-picker-right) > li.selected {
  background-color: lightgray;
}

:is(.meeting-times-picker-left, .meeting-times-picker-right) > li:hover {
  background-color: lightgray;
  cursor: pointer;
}
