.toastContainer {
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: max-content;
  max-width: 90vw;
  /* horizontally center */
  left: 50%;
  transform: translateX(-50%);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toast {
  animation: appear 0.5s;
  margin-bottom: 1rem;
  color: var(--custom-primary-contrast);
}

/* We want the bottom toast to be far above the BottomOverlay on small screens */
@media (max-width: 767.98px) {
  .toast:last-child {
    margin-bottom: 4rem;
  }
}

.toast.error {
  color: var(--custom-danger-contrast);
}

.toastHidden {
  opacity: 0;
  transition: opacity 0.5s;
}
