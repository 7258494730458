.alreadyHaveAccount {
  font-size: 0.9em;
  text-decoration: none;
  color: var(--custom-normal-text-color);
}

.alreadyHaveAccount:hover {
  color: var(--custom-primary);
  cursor: pointer;
}
