.daypicker-calendar {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.daypicker-dayofweek-cell {
  text-align: center;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid lightgray;
}

.daypicker-calendar-cell-month-indicator {
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--custom-primary-strong);
}

.daypicker-calendar__cell {
  height: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.daypicker-calendar__cell__button {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* media-breakpoint-down(md) */
@media (max-width: 767.98px) {
  .daypicker-calendar__cell {
    height: 4em;
  }
  .daypicker-calendar__cell__button {
    width: 2em;
    height: 2em;
  }
}

.daypicker-calendar__cell__button:hover {
  cursor: pointer;
}

.daypicker-calendar__cell__button.selected {
  background-color: var(--custom-primary);
  color: var(--custom-primary-contrast);
}

.daypicker-calendar__cell__button.unselected:hover {
  background-color: lightgray;
}
