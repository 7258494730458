.App {
  min-height: 100vh;
}

/* media-breakpoint-down(md) */
@media (max-width: 767.98px) {
  .App {
    font-size: 0.9rem;
  }
  .btn {
    --bs-btn-font-size: 0.9rem;
  }
  .w-100-md-down {
    width: 100%;
  }
  .container.custom-navbar-container {
    position: relative;
    justify-content: center;
  }
  .custom-navbar-container > .custom-navbar-toggle {
    position: absolute;
    left: 15px;
    border: none;
  }
  .custom-navbar-container > .navbar-brand {
    /* Bootstrap default is 1.25rem */
    --bs-navbar-brand-font-size: 1rem;
    /* Bootstrap default is 1rem */
    --bs-navbar-brand-margin-end: 0;
  }
}

.header-link {
  text-transform: uppercase;
  text-decoration: none;
  color: initial;
}

/* media-breakpoint-up(md) */
@media (min-width: 768px) {
  /* Need extra selectors for specificity */
  .navbar-nav .nav-link.header-link:not(:first-child) {
    padding-left: 1.5rem;
  }
}

.navbar-nav .nav-link:is(
  .header-link:hover,
  .header-link_active
 ) {
  color: var(--custom-primary);
}

.custom-link {
  text-decoration: none;
  color: initial;
}

.custom-link:hover {
  color: var(--custom-primary);
}

.custom-link.custom-link-inverted {
  color: var(--custom-primary-contrast);
}

.custom-link.custom-link-inverted:hover {
  color: var(--custom-primary-contrast);
}

.custom-btn-min-width {
  min-width: 6.5em;
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--custom-primary-weak-rgb), 0.25);
}

.toast-header-no-title .btn-close {
  display: block;
  --bs-toast-padding-x: auto;
  /* default is 1em */
  width: 0.5em;
  height: 0.5em;
}

footer {
  padding: 2rem 0;
}

footer a {
  margin: 0 1em;
  font-size: 0.9rem;
  text-decoration: none;
  color: var(--custom-secondary);
}

footer a:hover {
  color: var(--custom-secondary-strong);
}

.form-text-input {
  padding-bottom: 0.75rem;
}

body.modal-open {
  overflow: hidden;
}
