.loginForm {
  flex-grow: 1;
  max-width: 600px;
}

.dontHaveAccountLink {
  font-size: 0.9em;
}

.forgotPasswordLink {
  font-size: 0.8em;
}
