:root {
  --bs-primary-rgb: var(--custom-primary-rgb);
  --bs-secondary-rgb: var(--custom-secondary-rgb);

  --custom-scheduled-cell-color: violet;
  --custom-scheduled-cell-rgb: 238, 130, 238;
}

/* Custom colors */
/* See https://htmlcolorcodes.com/color-names/ */

/* TODO: dark theme (will need SASS mixins to avoid code duplication) */
:root, .light-theme {
  --custom-primary: forestgreen;
  --custom-primary-rgb: 34, 139, 34;
  --custom-primary-contrast: white;
  --custom-primary-strong: darkgreen;
  --custom-primary-weak: mediumseagreen;
  --custom-primary-weak-rgb: 60, 179, 113;

  --custom-secondary: rgb(108, 117, 125);
  --custom-secondary-rgb: 108, 117, 125;
  --custom-secondary-contrast: white;
  --custom-secondary-strong: rgb(92, 99, 106);
  --custom-secondary-weak: rgb(108, 117, 125);
  --custom-secondary-weak-rgb: 108, 117, 125;

  --custom-danger: rgb(220, 53, 69);
  --custom-danger-rgb: 220, 53, 69;
  --custom-danger-contrast: white;
  --custom-danger-strong: rgb(176, 45, 59);
  --custom-danger-weak: rgb(220, 53, 69);
  --custom-danger-weak-rgb: 220, 53, 69;

  --custom-active-shadow-rgb: 0, 0, 0;
  --custom-normal-text-color: black;
}

.btn-primary {
  --bs-btn-color: var(--custom-primary-contrast);
  --bs-btn-bg: var(--custom-primary);
  --bs-btn-border-color: var(--custom-primary);
  --bs-btn-hover-color: var(--custom-primary-contrast);
  --bs-btn-hover-bg: var(--custom-primary-strong);
  --bs-btn-hover-border-color: var(--custom-primary-strong);
  --bs-btn-focus-shadow-rgb: var(--custom-primary-weak-rgb);
  --bs-btn-active-color: var(--custom-primary-contrast);
  --bs-btn-active-bg: var(--custom-primary-strong);
  --bs-btn-active-border-color: var(--custom-primary-strong);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--custom-active-shadow-rgb), 0.125);
  --bs-btn-disabled-color: var(--custom-primary-contrast);
  --bs-btn-disabled-bg: var(--custom-primary-weak);
  --bs-btn-disabled-border-color: var(--custom-primary-weak);
}

.btn-outline-primary {
  --bs-btn-color: var(--custom-primary);
  --bs-btn-border-color: var(--custom-primary);
  --bs-btn-hover-color: var(--custom-primary-contrast);
  --bs-btn-hover-bg: var(--custom-primary);
  --bs-btn-hover-border-color: var(--custom-primary);
  --bs-btn-focus-shadow-rgb: var(--custom-primary-weak-rgb);
  --bs-btn-active-color: var(--custom-primary-contrast);
  --bs-btn-active-bg: var(--custom-primary);
  --bs-btn-active-border-color: var(--custom-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--custom-active-shadow-rgb), 0.125);
  --bs-btn-disabled-color: var(--custom-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--custom-primary-weak);
}

.btn-outline-secondary {
  --bs-btn-color: var(--custom-secondary);
  --bs-btn-border-color: var(--custom-secondary);
  --bs-btn-hover-color: var(--custom-secondary-contrast);
  --bs-btn-hover-bg: var(--custom-secondary);
  --bs-btn-hover-border-color: var(--custom-secondary);
  --bs-btn-focus-shadow-rgb: var(--custom-secondary-weak-rgb);
  --bs-btn-active-color: var(--custom-secondary-contrast);
  --bs-btn-active-bg: var(--custom-secondary);
  --bs-btn-active-border-color: var(--custom-secondary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--custom-active-shadow-rgb), 0.125);
  --bs-btn-disabled-color: var(--custom-secondary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--custom-secondary-weak);
}

.btn-outline-danger {
  --bs-btn-color: var(--custom-danger);
  --bs-btn-border-color: var(--custom-danger);
  --bs-btn-hover-color: var(--custom-danger-contrast);
  --bs-btn-hover-bg: var(--custom-danger);
  --bs-btn-hover-border-color: var(--custom-danger);
  --bs-btn-focus-shadow-rgb: var(--custom-danger-weak-rgb);
  --bs-btn-active-color: var(--custom-danger-contrast);
  --bs-btn-active-bg: var(--custom-danger);
  --bs-btn-active-border-color: var(--custom-danger);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(var(--custom-active-shadow-rgb), 0.125);
  --bs-btn-disabled-color: var(--custom-danger);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--custom-danger-weak);
}
