/* media-breakpoint-up(md) */
@media (min-width: 768px) {
  .meeting-avl-button {
    min-width: 8em;
  }
}

/* media-breakpoint-up(lg) */
@media (min-width: 992px) {
  .meeting-avl-button {
    min-width: 10em;
  }
}

.weeklyview__local_time_text {
  color: gray;
  font-size: 0.9em;
}

.weeklyview__bodycell {
  user-select: none;
  text-align: left;
  position: relative;
}

/*
  We don't want the grid lines to strike through the selected
  areas (which are coloured), since we want those to appear
  contiguous. Since we can't apply a z-index to borders, we
  create a pseudo-element with a lower z-index, so that the
  background-color of the real element appears on top of its
  border.
  Solution adapted from https://stackoverflow.com/a/28897204.
*/
.weeklyview__bodycell::after,
.weeklyview__bodycell_oddrow::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-right: 1px solid lightgray;
}

.weeklyview__bodycell_oddrow::after {
  border-bottom: 1px solid lightgray;
}

/*
  It's OK for the first/last borders to be on top, since they
  can't strike through a cell.
*/
.weeklyview__bodycell_firstrow {
  border-top: 1px solid lightgray;
}

.weeklyview__bodycell_firstcol {
  border-left: 1px solid lightgray;
}

.weeklyview__bodycell.selected {
  background-color: rgba(var(--custom-primary-rgb), 70%);
}

.weeklyview__bodycell.scheduling {
  background-color: rgba(var(--custom-scheduled-cell-rgb), 70%);
}

.weeklyview__bodycell:hover {
  cursor: pointer;
}

.canSelectDates .weeklyview__bodycell:hover {
  cursor: ns-resize;
}

.weeklyview__bodycell:hover::after {
  /*
    This needs to be in a pseudo-element because if we add a border to
    the cell itself, then its inner width/height will change (due to
    box-sizing: border-box), which will cause the external event boxes
    to change size because they use percentages for width and height.
  */
  border: 2px dashed black;
  z-index: unset;
}

.weeklyview__bodycell_scheduled_inner {
  width: 80%;
  left: 10%;
  right: 10%;
  font-size: 0.8em;
  color: white;
  background-color: var(--custom-scheduled-cell-color);
  /* should be shown on top of other people's availabilities */
  z-index: 1;
}

.weeklyview__bodycell_external_event {
  width: 100%;
  border-radius: 6px;
  background-color: rgba(var(--custom-scheduled-cell-rgb), 70%);
  padding-top: 0.3em;
  padding-left: 0.7em;
}

.weeklyview__bodycell_scheduled_inner,
.weeklyview__bodycell_external_event {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
}

.weeklyview__bodycell_external_event_text {
  font-size: 0.8em;
  line-height: 1.25;
}

.weeklyview-grid {
  text-align: right;
}

.weeklyview__hourcell {
  padding-right: 0.5em;
  font-size: 0.9em;
}

.weeklyview__colheadercell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weeklyview-grid__monthtext {
  grid-area: m;
  text-align: left;
  font-size: 1.2em;
  height: 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

/* media-breakpoint-down(md) */
@media (max-width: 767.98px) {
  .weeklyview__colheadercell, .weeklyview__hourcell, .weeklyview__bodycell {
    font-size: 0.8rem;
  }
}

.respondents-container {
  margin-top: 4em;
  margin-left: 1em;
  min-width: 10em;
}

.respondents-container ul {
  margin: 0;
  padding: 0;
}

.respondents-container li {
  list-style-type: none;
  margin: 1em 0;
}

.respondents-container li.unavailable {
  color: lightgray;
  text-decoration: line-through;
}

.respondents-container li:hover {
  color: var(--custom-primary);
  cursor: pointer;
}
