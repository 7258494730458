@use "sass:map";
@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-only(sm) {
  .app-main-container {
    max-width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .app-main-container {
    padding: 0;
  }
}

@include media-breakpoint-only(md) {
  .app-main-container {
    max-width: max(90%, map.get($container-max-widths, "md"));
  }
}
